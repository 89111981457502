import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import {
  clearClient,
  setClient,
  setClientID,
  setCompanyCode,
  setIsAdmin,
  setProjectID,
  setUser,
} from "../../../Redux/Actions/actions.js";

// Check if the user is signed in function
export const checkSignIn = async (code) => {
  try {
    const res = await axios.get(`/api/client/sign-in/${code}`, {
      withCredentials: true,
    });

    return res.data;
  } catch (err) {
    console.error(err);
  }
};

// Custom hook to manually sign in the user
export default function useManualLogin(
  dispatch,
  setAdminUser,
  setCompanyName,
  setOpenErrorSnackbar,
  setOpenSuccessSnackbar,
  setVerified,
  user,
) {
  const signIn = useMutation({
    mutationFn: (code) => checkSignIn(code),
    onSuccess: async (data) => {
      if (data.is_admin === true) {
        dispatch(setIsAdmin(true));
        dispatch(clearClient());
        setAdminUser(() => true);
        setCompanyName(() => "Argus Construction Services");
        setOpenSuccessSnackbar(() => true);
        setVerified(() => true);
        dispatch(
          setUser({
            id: "argus_admin",
            companyCode: data.companyCode,
            name: "Argus Admin",
            email: "info@arguscs.com",
            phone: "602-237-5967",
            title: "Argus Admin",
            admin: true,
            currentClient: null,
            currentProject: null,
            hideArchived: false,
          }),
        );
      } else if (data.is_admin === false) {
        const clientData = data.client;
        const companyCode = data.companyCode;
        const clientId = clientData.id;
        const [address, city, zipCode] = clientData.address.split(", ");
        dispatch(setCompanyCode(companyCode));
        dispatch(setIsAdmin(false));
        dispatch(
          setClient({
            id: clientId,
            name: clientData.name,
            shortName: clientData.shortName,
            address,
            city,
            zip: zipCode,
            phone: clientData.phone,
          }),
        );
        dispatch(setClientID(clientId));
        dispatch(setProjectID(null));
        setAdminUser(() => false);
        if (user && user.name && user.currentClient === clientId) {
          setCompanyName(() => user.name);
        } else {
          setCompanyName(() => clientData.name);
          dispatch(
            setUser({
              id: null,
              companyCode: data.companyCode,
              name: "",
              email: "",
              phone: "",
              title: "",
              admin: false,
              currentClient: clientId,
              currentProject: null,
            }),
          );
        }
        setOpenSuccessSnackbar(() => true);
        setVerified(() => true);
      }
    },
    onError: (error) => {
      setOpenErrorSnackbar(() => true);
      setVerified(() => false);
    },
  });

  return signIn;
}
