import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";

// Function to update the request status of a project
export const updateRequestStatus = async (clientId, projectId, status) => {
  console.log(status);
  const response = await axios.patch(
    `/api/update-project/${clientId}/${projectId}/request-status/`,
    {
      requestStatus: status,
    },
    {
      withCredentials: true,
    },
  );

  return response.data;
};

// Custom hook to update the request status of a project
export default function useSetRequestStatus(
  clientId,
  projectId,
  emailClient,
  generateSwpppNarrative,
  navigate,
) {
  const { queryClient } = useContext(DashboardContext);
  const setRequestStatus = useMutation({
    mutationFn: (status) => {
      return updateRequestStatus(clientId, projectId, status);
    },
    onSuccess: async (data) => {
      console.log("SUCCESSFULLY UPDATED REQUEST STATUS: ", data);
      await emailClient();
      if (data.requestStatus === "C") {
        await generateSwpppNarrative();
      }

      await queryClient.invalidateQueries({
        queryKey: ["adminRows"],
        type: "all",
      });

      navigate("/dashboard/admin");
    },
    onError: (error) => {
      console.log("FAILED TO UPDATE REQUEST STATUS: ", error);
    },
  });

  return setRequestStatus;
}
