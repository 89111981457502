import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import SyncIcon from "@mui/icons-material/Sync";
import Chip from "@mui/material/Chip";
import Pagination from "@mui/material/Pagination";
import Tooltip from "@mui/material/Tooltip";
import {
  GridPagination,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import React from "react";
import "../../Tooltip/styles/tooltip.css";
import * as defaultStyles from "../styles/main-datagrid.module.css";

// Components shared by both admin and client data grids
// Footer pagination component for the data grid
export function PaginationActions({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      className={`${className} ${defaultStyles["grid-pagination"]}`}
      variant="text"
      classes={{
        root: defaultStyles["grid-pagination-root"],
        text: defaultStyles["pagination-item-text"],
        ul: defaultStyles["pagination-ul"],
      }}
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => onPageChange(event, value - 1)}
      siblingCount={0}
    />
  );
}

export function CustomGridPagination(props) {
  return (
    <GridPagination
      ActionsComponent={PaginationActions}
      // classes={props.classes}
      {...props}
    />
  );
}

// Component for the project status column in the data grid
export function RequestStatusCell({ params, isAdmin }) {
  const { id, value } = params;

  // If the status is "I" (Incomplete)
  if (value === "I") {
    return (
      <Tooltip
        title={`This request has not been submitted and currently is available to edit. Click the 'EDIT' button to begin editing this projects services questionnaire.`}
        id="requestStatus-tooltip"
        classes={{
          tooltip: "dashboard-tooltip",
          popper: "dashboard-tooltip-popper",
          arrow: "dashboard-arrow",
        }}
        placement="right"
      >
        <Chip
          key={`incomplete-${id}`}
          icon={<EditIcon className={defaultStyles["incompleteCheck"]} />}
          label={"Editable"}
          id={defaultStyles["incompleteChip"]}
          classes={{
            label: defaultStyles["chipStatusLabel"],
            root: defaultStyles["incompleteChipRoot"],
          }}
        />
      </Tooltip>
    );
    // If the status is "P" (Pending)
  } else if (value === "P") {
    return (
      <Tooltip
        title={
          isAdmin
            ? `This projects services questionnaire has been submitted (${params.row.submittedAt}) and is currently awaiting verification/approval. Click the "REVIEW" button to suggest revisions or validate this request.`
            : `This projects services questionnaire has been submitted (${params.row.submittedAt}) and is currently awaiting verification/approval from an Argus team member. You can view a read-only version of the services questionnaire form by clicking the 'VIEW' button. A downloadable PDF copy of the services questionnaire will be available when approved`
        }
        id="requestStatus-tooltip"
        classes={{
          tooltip: "dashboard-tooltip",
          popper: "dashboard-tooltip-popper",
          arrow: "dashboard-arrow",
        }}
        placement="right"
      >
        <Chip
          key={`pending-${id}`}
          icon={
            <AccessTimeFilledIcon className={defaultStyles["pendingWheel"]} />
          }
          label={"Pending"}
          id={defaultStyles["pendingChip"]}
          classes={{
            label: defaultStyles["chipStatusLabel"],
            root: defaultStyles["pendingChipRoot"],
          }}
        />
      </Tooltip>
    );
    // If the status is "IP" (In Progress)
  } else if (value === "IP") {
    return (
      <Tooltip
        title={`This projects questionnire is currently being filled out by another user. Click the 'VIEW' button to view the a read-only version of the services questionnaire form, or wait until the status is 'Editable' to begin editing the form`}
        id="requestStatus-tooltip"
        classes={{
          tooltip: "dashboard-tooltip",
          popper: "dashboard-tooltip-popper",
          arrow: "dashboard-arrow",
        }}
        placement="right"
      >
        <Chip
          key={`inProgress-${id}`}
          icon={<SyncIcon className={defaultStyles["progressCircle"]} />}
          label={"In Progress"}
          id={defaultStyles["inProgressChip"]}
          classes={{
            label: defaultStyles["chipStatusLabel"],
            root: defaultStyles["inProgressChipRoot"],
          }}
        />
      </Tooltip>
    );
    // If the status is "RV" (Revise)
  } else if (value === "RV") {
    return (
      <Tooltip
        title="An Argus team member has marked information in this form as invalid and may require revisions. You can update & resubmit this form by clicking the 'Revise' button"
        id="requestStatus-tooltip"
        classes={{
          tooltip: "dashboard-tooltip",
          popper: "dashboard-tooltip-popper",
          arrow: "dashboard-arrow",
        }}
        placement="right"
      >
        <Chip
          key={`revision-${id}`}
          icon={<PriorityHighIcon className={defaultStyles["reviseBox"]} />}
          label={"Revisions"}
          id={defaultStyles["reviseChip"]}
          classes={{
            root: defaultStyles["reviseChipRoot"],
            label: defaultStyles["chipStatusLabel"],
          }}
        />
      </Tooltip>
    );
    // If the status is "C" (Complete)
  } else if (value === "C") {
    return (
      <Tooltip
        title={
          isAdmin
            ? `This projects questionnaire is approved and complete (${params.row.completedAt}). You can view/download a PDF copy of the services questionniare by clicking the 'DOWNLOAD' button. This projects request may be archived by selecting the archive/bookmark icon in the 'Archive' column.`
            : `This projects questionnaire has been approved by an Argus team member (${params.row.completedAt}). You can view/download a PDF copy of the services questionniare by clicking the 'DOWNLOAD' button. This projects request may be archived by selecting the archive/bookmark icon in the 'Archive' column.`
        }
        id="requestStatus-tooltip"
        classes={{
          tooltip: "dashboard-tooltip",
          popper: "dashboard-tooltip-popper",
          arrow: "dashboard-arrow",
        }}
        placement="right"
      >
        <Chip
          icon={<CheckIcon className={defaultStyles["completedCheck"]} />}
          label={params.row.archived ? "Archived" : "Completed"}
          className={params.row.archived ? defaultStyles["archived-chip"] : ""}
          id={defaultStyles["completedChip"]}
          classes={{
            root: defaultStyles["completedChipRoot"],
            label: defaultStyles["chipStatusLabel"],
          }}
        />
      </Tooltip>
    );
  }
}
