import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

// Function to update or create a questionnaire
export const updateQuestionnaire = async (clientId, projectId) => {
  if (!clientId || !projectId) return {};
  try {
    const response = await axios.patch(
      `/api/questionnaire/${clientId}/${projectId}/`,
      {
        withCredentials: true,
      },
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Custom hook to update a questionnaire
export default function useUpdateQuestionnaire(clientId, projectId) {
  const queryClient = useQueryClient();
  const setQuestionnaire = useMutation({
    mutationFn: () => {
      updateQuestionnaire(clientId, projectId);
    },
    onError: (err, variables, context) => {
      if (!projectId) return;
      console.log(`Error updating questionnaire: `, err);
    },
    onSettled: (data) => {
      console.log("UPDATE QUESTIONNAIRE SETTLED: ", data);
      queryClient.invalidateQueries(["questionnaire", clientId, projectId]);
    },
    onSuccess: (data) => {
      console.log("SUCCESSFUL QUESTIONNAIRE UPDATE: ", data);
    },
  });

  return setQuestionnaire;
}
