import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Function to get invalid inputs
export const getInvalidInputs = async (clientId, projectId) => {
  try {
    const response = await axios.get(
      `/api/invalid-inputs/${clientId}/${projectId}/`,
      {
        withCredentials: true,
      },
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Custom hook to get invalid inputs
export default function useInvalidInputs(clientId, projectId) {
  return useQuery({
    queryKey: ["invalid-inputs", clientId, projectId],
    queryFn: () => getInvalidInputs(clientId, projectId),
    enabled: !!clientId && !!projectId,
  });
}
