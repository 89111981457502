import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { updateUser } from "../../../../Redux/Actions/actions";
import { fetchClientRows } from "../../Client/ClientQueries/useClientRows";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";

// Function to check if a user exists
const checkUser = async (data, clientId) => {
  const res = await axios.post(`/api/client/${clientId}/new-user/`, data, {
    withCredentials: true,
  });

  return res;
};

// Custom hook to check if a user exists
const useGetUser = (
  dispatch,
  clientId,
  setOpenModal,
  setOpenSnackbar,
  setSnackbarMessage,
  setSnackbarSeverity,
) => {
  const { queryClient } = useContext(DashboardContext);
  const getUser = useMutation({
    mutationFn: (data) => checkUser(data, clientId),
    onSuccess: async (res) => {
      if (res.status === 200) {
        dispatch(
          updateUser({
            id: res.data.id,
            name: res.data.name,
            email: res.data.email,
            title: res.data.title,
            phone: res.data.phone,
            admin: false,
            currentClient: res.data.client,
          }),
        );
        setSnackbarSeverity("success");
        setSnackbarMessage(`Welcome, ${res.data.name}!`);
        setOpenSnackbar(true);
        setOpenModal(false);

        await queryClient.ensureQueryData({
          queryKey: ["clientRows", clientId],
          queryFn: () => fetchClientRows(clientId, false, res.data.id),
        });
      }
    },
    onError: (err) => {
      console.log(err);
      setSnackbarSeverity("error");
      setSnackbarMessage(`User info not found.`);
      setOpenSnackbar(true);
    },
  });

  return getUser;
};

export default useGetUser;
