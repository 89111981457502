import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Function to fetch validate questionnaire
export const fetchValidateQuestionnaire = async (clientId, projectId) => {
  try {
    const response = await axios.get(
      `/api/validate-questionnaire/${clientId}/${projectId}/`,
      {
        withCredentials: true,
      },
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Custom hook to fetch validate questionnaire
export default function useValidateQuestionnaire(clientId, projectId) {
  return useQuery({
    queryKey: ["validate-questionnaire", clientId, projectId],
    queryFn: () => fetchValidateQuestionnaire(clientId, projectId),
    enabled: !!clientId && !!projectId,
  });
}
