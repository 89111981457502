import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Fetch a questionnaire function
export const fetchQuestionnaire = async (clientId, projectId) => {
  try {
    const response = await axios.get(
      `/api/questionnaire/${clientId}/${projectId}/`,
      {
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Custom hook to fetch a questionnaire
export default function useQuestionnaire(clientId, projectId) {
  return useQuery({
    queryKey: ["questionnaire", clientId, projectId],
    queryFn: () => fetchQuestionnaire(clientId, projectId),
    enabled: !!clientId && !!projectId,
  });
}
