import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

// Function to update invalid inputs
export const updateInvalidInputs = async (clientId, projectId, data) => {
  const response = await axios.post(
    `/api/invalid-inputs/${clientId}/${projectId}/`,
    data,
    {
      withCredentials: true,
    },
  );

  return response.data;
};

// Custom hook to update invalid inputs
export default function useSetInvalidInputs(clientId, projectId) {
  const queryClient = useQueryClient();
  const setInvalidInputs = useMutation({
    mutationFn: (data) => updateInvalidInputs(clientId, projectId, data),
    onSettled: () => {
      queryClient.invalidateQueries(["invalid-inputs", clientId, projectId]);
    },
    onError: (error) => {
      console.log("ADD INVALID INPUT ERROR: ", error);
    },
  });

  return setInvalidInputs;
}
