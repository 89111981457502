import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";

// Function to update a form slide
export const updateFormSlide = async (
  clientId,
  projectId,
  slideNumber,
  data,
) => {
  const response = await axios.patch(
    `/api/update-project-slide/${clientId}/${projectId}/${slideNumber}/`,
    data,
    {
      withCredentials: true,
    },
  );

  return response.data;
};

// Custom hook to update a form slide
export default function useUpdateFormSlide(clientId, projectId, slideNumber) {
  const { queryClient } = useContext(DashboardContext);
  const setFormSlide = useMutation({
    mutationFn: (data) =>
      updateFormSlide(clientId, projectId, slideNumber, data),
    onError: (error) => {
      console.log("ERROR UPDATING FORM SLIDE: ", error);
    },
    onSettled: async () => {
      //   Invalidate and refetch queries
      await queryClient.invalidateQueries({
        queryKey: ["currentProject", clientId, projectId],
        refetchType: "all",
      });
      await queryClient.invalidateQueries({
        queryKey: ["clientRows", clientId],
        refectType: "all",
      });
      await queryClient.invalidateQueries({
        queryKey: ["adminRows"],
        refectType: "all",
      });
    },
  });

  return setFormSlide;
}
